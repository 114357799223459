import React, { useContext } from "react";
import { COLUMN, columnCategories } from "../utils/columns";
import { useDispatch, useSelector } from "react-redux";
import { updateApplicationData, updateData, updateSalesForceData } from "../redux/actions/appActions";
import { getColumnFilterCookies } from "../utils/cookies";
import { AppContext, TableFilterContext } from "../App";
import { pages } from "./usePage";
import { TABS_KEYS } from "../pages/NPSDashboard/components/DashboardBody";

// aggregation logic
export default function useAggregation(page) {
  const { duplicateData, duplicateApplicationData, duplicateSalesforceData } = useSelector((state) => state.app);
  const columnStore = useSelector((state) => state.app.columns);
  const { tableRef, tableRefTwo, npsTableRef, npsTableRefTwo } = useContext(TableFilterContext);
  const { setCurrentPage, setCurrentPageTwo, activeTab } = useContext(AppContext);
  const dispatch = useDispatch();

  const applyFilters = React.useCallback((data, columns, category) => {
    const filterConfiguration =
      category === columnCategories.SFDC_COLUMNS || category === columnCategories.PRODUCT_CUSTOMER_DATA
        ? getColumnFilterCookies(2)
        : getColumnFilterCookies(1);

    if (!filterConfiguration) return data;
    let filteredCols = Object.keys(filterConfiguration);
    filteredCols = columns.filter((col) => filteredCols.includes(col.dataIndex));

    data = data.filter((row) => {
      let match = true;
      filteredCols.forEach((col) => {
        if (filterConfiguration[col.dataIndex].length > 0) {
          match &= !filterConfiguration[col.dataIndex].includes(row[col.dataIndex]);
        }
      });
      return match;
    });

    return data;
  }, []);

  const getVisibleColumns = React.useCallback((columns, category) => {
    //columns to be excluded in aggregation
    const nonDimensionCols = new Set([
      "select",
      "NetSales",
      "Margin",
      "NPSPercentageNew",
      "NPSPercentageRenew",
      "SustnAdvgedSales",
      "SustnAdvgedSalesPercentage",
      "Project",
      "Commentary",
      "TaggedBy",
      "lastmodifiedtimestamp",
    ]);

    if (
      category !== columnCategories.APPLICATION_TAGGING &&
      category !== columnCategories.PRODUCT_CUSTOMER_DATA &&
      category !== columnCategories.SFDC_COLUMNS
    ) {
      const colsToBeIgnoredinProductData = [COLUMN.QualifyAsNPS, COLUMN.NPSCriteria, COLUMN.NPSYear];
      colsToBeIgnoredinProductData.forEach((col) => nonDimensionCols.add(col));
    }

    return columns.filter((col) => col.visibility && !nonDimensionCols.has(col.dataIndex)).map((col) => col.dataIndex);
  }, []);

  const aggregate = React.useCallback(
    (data, cols, category) => {
      if (!data || data.length === 0) return [];

      let aggregatedData = [];
      const visibleColIndices = getVisibleColumns(cols, category);
      const uniqueKey = (row) => visibleColIndices.map((col) => row[col]).join("|"); // Generates a unique key based on visible columns
      const aggregatedMap = new Map();
      data.forEach((row) => {
        const key = uniqueKey(row);
        if (!aggregatedMap.has(key)) {
          const newRow = { ...row };
          if (!newRow?.Hashes) {
            newRow.Hashes = [row.HashKey];
          }
          aggregatedData.push(newRow);
          aggregatedMap.set(key, newRow);
        } else {
          // aggregating non-dimensional columns (NetSales, Margin, Percentages)
          const aggRow = aggregatedMap.get(key);
          aggRow.NetSales += row.NetSales;
          aggRow.Margin += row.Margin;
          aggRow.Hashes.push(row.HashKey);
          const hashesLength = aggRow.Hashes.length;
          aggRow.NPSPercentageNew = (aggRow.NPSPercentageNew * (hashesLength - 1) + row.NPSPercentageNew) / hashesLength;
          aggRow.NPSPercentageRenew = (aggRow.NPSPercentageRenew * (hashesLength - 1) + row.NPSPercentageRenew) / hashesLength;
          aggRow.SustnAdvgedSalesPercentage =
            (aggRow.SustnAdvgedSalesPercentage * (hashesLength - 1) + row.SustnAdvgedSalesPercentage) / hashesLength;
        }
      });

      return aggregatedData;
    },
    [getVisibleColumns]
  );

  const resetFilters = React.useCallback(
    (data, filteredData, table) => {
      if (table === 1) {
        if (tableRef) tableRef.reset(data, false);
        if (npsTableRef) npsTableRef.reset(filteredData, false);
      } else {
        if (tableRefTwo) tableRefTwo.reset(data, false);
        if (npsTableRefTwo) npsTableRefTwo.reset(filteredData, false);
      }
    },
    [tableRef, npsTableRef, tableRefTwo, npsTableRefTwo]
  );

  const resetPage = React.useCallback(
    (category) => {
      if (category === columnCategories.PRODUCT_DATA || category === columnCategories.NPS_TAGGING_DATA) {
        // table => 1
        setCurrentPage(1);
      } else if (category === columnCategories.PRODUCT_CUSTOMER_DATA || category === columnCategories.APPLICATION_TAGGING) {
        // table => 2
        setCurrentPageTwo(1);
      } else if (category === columnCategories.SFDC_COLUMNS) {
        // table => 2
        setCurrentPageTwo(1);
      }
    },
    [setCurrentPage, setCurrentPageTwo]
  );

  const getCols = React.useCallback(
    (category) => {
      if (page === pages.SAP) {
        if (category === columnCategories.PRODUCT_DATA || category === columnCategories.NPS_TAGGING_DATA) {
          return [...columnStore[columnCategories.PRODUCT_DATA], ...columnStore[columnCategories.NPS_TAGGING_DATA]];
        } else return [...columnStore[columnCategories.PRODUCT_CUSTOMER_DATA], ...columnStore[columnCategories.APPLICATION_TAGGING]];
      } else if (page === pages.salesforce) {
        return [...columnStore[columnCategories.SFDC_COLUMNS], ...columnStore[columnCategories.APPLICATION_TAGGING]];
      } else if (page === pages.laird) {
        return [...columnStore[columnCategories.LAIRD], ...columnStore[columnCategories.NPS_TAGGING_DATA]];
      } else if (page === pages.spectrum) {
        return [...columnStore[columnCategories.SPECTRUM], ...columnStore[columnCategories.NPS_TAGGING_DATA]];
      }
      return [];
    },
    [columnStore, page]
  );

  const getData = React.useCallback(() => {
    // _initial_data is passed when this function gets called on load.
    if (page === pages.salesforce) return duplicateSalesforceData;
    else return activeTab === TABS_KEYS.PRODUCT ? duplicateData : duplicateApplicationData;
  }, [activeTab, duplicateApplicationData, duplicateData, duplicateSalesforceData, page]);

  const updateAggregatedData = React.useCallback(
    (category, aggregatedData, filteredData, filtersApplied) => {
      if (page === pages.salesforce) {
        dispatch(updateSalesForceData(aggregatedData));
        if (filtersApplied) resetFilters(aggregatedData, 2);
      } else {
        if (category === columnCategories.PRODUCT_CUSTOMER_DATA || category === columnCategories.APPLICATION_TAGGING) {
          dispatch(updateApplicationData(aggregatedData));
          if (filtersApplied) resetFilters(aggregatedData, filteredData, 2);
        } else {
          dispatch(updateData(aggregatedData));
          if (filtersApplied) resetFilters(aggregatedData, filteredData, 1);
        }
      }
    },
    [dispatch, page, resetFilters]
  );

  const aggregateData = React.useCallback(
    async (columns, category, initial_data = null, filtersApplied = true) => {
      const newCols = getCols(category);
      let _data = initial_data != null ? initial_data : getData();

      const aggregatedData = aggregate(_data, newCols, category);
      const filteredData = filtersApplied ? applyFilters(aggregatedData, columns, category) : _data;

      updateAggregatedData(category, aggregatedData, filteredData, filtersApplied);
      resetPage(category);

      return aggregatedData;
    },
    [aggregate, applyFilters, getCols, getData, resetPage, updateAggregatedData]
  );

  const aggregateProductData = React.useCallback(
    (data, filtersApplied = false) =>
      aggregateData(columnStore[columnCategories.PRODUCT_DATA], columnCategories.PRODUCT_DATA, data, filtersApplied),
    [aggregateData, columnStore]
  );

  const aggregateApplicationData = React.useCallback(
    (data, filtersApplied = false) =>
      aggregateData(columnStore[columnCategories.PRODUCT_CUSTOMER_DATA], columnCategories.PRODUCT_CUSTOMER_DATA, data, filtersApplied),
    [aggregateData, columnStore]
  );

  const aggregateSalesforceDataOnLoad = React.useCallback(
    (data, filtersApplied = false) =>
      aggregateData(columnStore[columnCategories.SFDC_COLUMNS], columnCategories.SFDC_COLUMNS, data, filtersApplied),
    [aggregateData, columnStore]
  );

  const aggregateSAPDataOnLoad = React.useCallback(
    (data, filtersApplied = false) => {
      aggregateProductData(data, filtersApplied);
      aggregateApplicationData(data, filtersApplied);
    },
    [aggregateProductData, aggregateApplicationData]
  );

  return {
    aggregateData,
    aggregateSAPDataOnLoad,
    aggregateSalesforceDataOnLoad,
    aggregateProductData,
    aggregateApplicationData,
  };
}
