import React, { useContext } from "react";
import { AppContext } from "../App";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFilterCookies } from "../utils/cookies";
import {
  setUpdatesOne,
  setUpdatesTwo,
  updateApplicationData,
  updateAppLoading,
  updateData,
  updateDuplicateApplicationData,
  updateDuplicateData,
  updateDuplicateSalesForceData,
  updateNPSTagging,
} from "../redux/actions/appActions";
import useAggregation from "./useAggregations";
import useValidation from "./useValidation";
import { pages } from "./usePage";
import useTableUtils from "./useTableUtils";
import { TABS_KEYS } from "../pages/NPSDashboard/components/DashboardBody";
import { DEFAULT_MESSAGE_DURATION, LOADING_MESSAGE_DURATION } from "../constants/global.const";

export default function useTagging(page) {
  const {
    toolbarForm,
    messageApi,
    rowsToBeUpdated,
    rowsToBeUpdatedForTableTwo,
    salesforceRowsTobeUpdated,
    setSalesforceRowsTobeUpdated,
    setRowsToBeUpdated,
    setRowsToBeUpdatedForTableTwo,
    activeTab,
    modal,
  } = useContext(AppContext);

  const { updatesOne, updatesTwo } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { aggregateProductData, aggregateApplicationData, aggregateSalesforceDataOnLoad } = useAggregation(page);
  const { validateTagging } = useValidation();
  const { getDataFromStore } = useTableUtils();
  const [data, duplicateData] = getDataFromStore(page);

  const warn = (resOne, resTwo) => {
    const [percentError, percentZero, criteriaError, NPSYearError, npsTagError] = resOne;
    const [percentErrorTwo, percentZeroTwo, criteriaErrorTwo, NPSYearErrorTwo, npsTagErrorTwo] = resTwo;
    Modal.warning({
      title: "Error",
      content: (
        <>
          {(percentError || percentErrorTwo) && <p>* New NPS % + Renew NPS % should not exceed 100%.</p>}
          {(criteriaError || criteriaErrorTwo) && <p>* NPS Criteria is required.</p>}
          {(NPSYearError || NPSYearErrorTwo) && <p>* NPS Year is required.</p>}
          {(percentZero || percentZeroTwo) && <p>* New NPS % + Renew NPS % should not be 0.</p>}
          {(npsTagError || npsTagErrorTwo) && <p>* The same material cannot be tagged as NPS and Application.</p>}
        </>
      ),
    });
  };

  //updatedRows is an array of rowIndexes of updated rows
  // updates is an object with key as rowIndex and value as updated values
  const merge = (updatedRows, updates) => {
    if (updatedRows && updatedRows.length) {
      return updatedRows.map((row) => updates[row]);
    }
    return [];
  };
  const mergeUpdates = () => {
    let newRows = [];
    newRows = merge(rowsToBeUpdated, updatesOne);
    newRows = [...newRows, ...merge(rowsToBeUpdatedForTableTwo, updatesTwo)];
    newRows = [...newRows, ...merge(salesforceRowsTobeUpdated, updatesTwo)];
    newRows = newRows.filter((row) => row?.HashKey); // picks nonempty rows
    return newRows;
  };

  const getIndividualRowsForAggregatedRows = React.useCallback((aggregatedRows) => {
    return aggregatedRows.reduce((acc, row) => {
      const rows = row.Hashes.map((hash) => ({
        ...row,
        HashKey: hash,
        Hashes: null,
      }));
      return acc.concat(rows);
    }, []);
  }, []);

  const taggingHelper = () => {
    const resOne = validateTagging(rowsToBeUpdated, updatesOne, 1);
    const resTwo = validateTagging(rowsToBeUpdatedForTableTwo, updatesTwo, 2);
    if (resOne.includes(true) || resTwo.includes(true)) {
      warn(resOne, resTwo);
      return;
    }
    let rows = mergeUpdates();
    if (rows.length === 0) {
      messageApi.open({
        key: "update",
        type: "warning",
        content: "Please select a row or Enter new values to update",
        duration: DEFAULT_MESSAGE_DURATION,
      });
      return;
    }
    messageApi.open({
      key: "update",
      type: "loading",
      content: "Updating data, Please Hang on...",
      duration: LOADING_MESSAGE_DURATION,
    });
    rows = getIndividualRowsForAggregatedRows(rows);
    return rows;
  };

  const getUpdatedArray = (duplicateData, res) => {
    let _duplicateData = JSON.parse(JSON.stringify(duplicateData));
    res.forEach((row) => {
      const idx = _duplicateData.findIndex((_row) => _row.HashKey === row.HashKey);
      if (idx > -1) {
        _duplicateData.splice(idx, 1, row);
      }
    });
    return _duplicateData;
  };
  const submitTagging = async () => {
    const filterCookies = getFilterCookies();
    try {
      let rows = taggingHelper();
      if (!rows) return;
      const { res, applications, nps } = await updateNPSTagging({ hier2: filterCookies.hier2, rows });

      if (applications?.length || nps?.length) {
        messageApi.destroy("update");
        modal.warning({
          title: "Unable to submit tagging for the following materials.",
          content: (
            <>
              Following materials can not be tagged as {applications?.length ? "NPS" : "Application"}. since, they are already tagged as
              &nbsp;{applications?.length ? "Application" : "NPS"} for the same sales year.
              <br />
              <ul>
                {applications?.map((material) => (
                  <li key={material}>{material}</li>
                ))}
                {nps?.map((material) => (
                  <li key={material}>{material}</li>
                ))}
              </ul>
            </>
          ),
        });
      } else {
        messageApi.open({
          key: "update",
          type: "success",
          content: "Rows Updated.",
          duration: DEFAULT_MESSAGE_DURATION,
        });
      }
      if (activeTab === TABS_KEYS.PRODUCT) {
        // eslint-disable-next-line no-unused-vars
        const [_, duplicate] = getDataFromStore(page, 1);
        const updatedArray = getUpdatedArray(duplicate, res);
        aggregateProductData(updatedArray, true);
        dispatch(updateDuplicateData(updatedArray));
        dispatch(updateDuplicateApplicationData([]));
        dispatch(updateApplicationData([]));
      } else {
        // eslint-disable-next-line no-unused-vars
        const [_, duplicate] = getDataFromStore(page, 2);
        const updatedArray = getUpdatedArray(duplicate, res);
        aggregateApplicationData(updatedArray, true);
        dispatch(updateDuplicateApplicationData(updatedArray));
        dispatch(updateDuplicateData([]));
        dispatch(updateData([]));
      }
      dispatch(setUpdatesOne({}));
      dispatch(setUpdatesTwo({}));
      // newRows is nothing but updated rows.
      const deselectRows = (rowIndices, table) => {
        rowIndices.forEach((id) => {
          const checkbox = document.getElementById(`check-row-${table}-${id}`);
          if (checkbox) checkbox.checked = false;
        });
      };

      deselectRows(rowsToBeUpdated, 1);
      deselectRows(rowsToBeUpdatedForTableTwo, 2);

      setRowsToBeUpdated([]);
      setRowsToBeUpdatedForTableTwo([]);
      toolbarForm.resetFields();
      dispatch(updateAppLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(updateAppLoading(false));
      messageApi.open({
        key: "update",
        type: "error",
        content: "Something went wrong, Please try again. ",
        duration: DEFAULT_MESSAGE_DURATION,
      });
    }
  };

  const submitSalesforceTagging = async (business = pages.liveo) => {
    try {
      let rows = taggingHelper();
      if (!rows) return;
      const { res } = await updateNPSTagging({
        hier2: "ELECTRONICS & INDUSTRIAL",
        hier5: business,
        dataType: "salesforce",
        rows,
      });
      messageApi.open({
        key: "update",
        type: "success",
        content: "Rows Updated.",
        duration: DEFAULT_MESSAGE_DURATION,
      });
      res.forEach((row) => {
        const idx = duplicateData.findIndex((_row) => _row.HashKey === row.HashKey);
        if (idx > -1) {
          duplicateData.splice(idx, 1, row);
        }
      });
      aggregateSalesforceDataOnLoad(duplicateData, true);
      dispatch(updateDuplicateSalesForceData(duplicateData));
      dispatch(setUpdatesTwo({}));
      setRowsToBeUpdated([]);
      rowsToBeUpdatedForTableTwo.forEach((row, index) => {
        const checkbox = document.getElementById(`check-row-2-${row}`);
        if (checkbox) checkbox.checked = false;
      });
      setRowsToBeUpdatedForTableTwo([]);
      setSalesforceRowsTobeUpdated([]);
      toolbarForm.resetFields();
    } catch (err) {
      messageApi.open({
        key: "update",
        type: "error",
        content: "Something went wrong, please try again",
        duration: DEFAULT_MESSAGE_DURATION,
      });
    }
  };

  return { submitTagging, submitSalesforceTagging };
}
