import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { pages, usePage } from "../hooks/usePage";
import { getSession } from "../utils/cookies";

const NavItem = ({ url, text }) => {
  return (
    <li className="p-1 text-sm cursor-pointer hover:font-bold hover:text-[#57b2fc]">
      <NavLink to={url} className="text-black no-underline">
        {text}
      </NavLink>
    </li>
  );
};

function NavBar() {
  const { roles } = useSelector((state) => state.auth);
  const hasISAccess = roles.includes("GLOBAL_IMR_RLS_PR3_INDUSTRIAL_SOLUTIONS");
  const hasICSAccess = roles.includes("GLOBAL_IMR_RLS_PR3_INTERCONNECT_SOLUTIONS");
  const isDev = roles.includes("NPS_DEVELOPER") || roles.includes("NPS_PROD_VIEWER");
  const page = usePage();
  const session = getSession();
  const LAST_VISITED_PAGE = session ? session["LAST_VISITED_PAGE"] : null;

  return (
    <nav className="bg-[#ebebeb] sticky top-14 z-10">
      <ul className="flex flex-row gap-4 ml-5 list-none nav-list">
        {page === pages.guidelines ? (
          LAST_VISITED_PAGE === pages.SAP ? (
            <NavItem url="/" text="NPS Evaluation" />
          ) : LAST_VISITED_PAGE === pages.laird ? (
            <NavItem url="/laird" text="Laird" />
          ) : LAST_VISITED_PAGE === pages.spectrum ? (
            <NavItem url="/spectrum" text="Spectrum" />
          ) : LAST_VISITED_PAGE === pages.salesforce ? (
            <NavItem url="/salesforce" text="Salesforce" />
          ) : (
            <NavItem url="/" text="NPS Evaluation" />
          )
        ) : null}
        {page === pages.SAP ? (
          <NavItem url="/" text="NPS Evaluation" />
        ) : (hasICSAccess || isDev) && page === pages.laird ? (
          <NavItem url="/laird" text="Laird" />
        ) : (hasISAccess || isDev) && page === pages.spectrum ? (
          <NavItem url="/spectrum" text="Spectrum" />
        ) : (hasISAccess || isDev) && page === pages.salesforce ? (
          <NavItem url="/salesforce" text="Salesforce" />
        ) : null}
        <NavItem url="/guidelines" text="Guidelines" />
      </ul>
    </nav>
  );
}
export default NavBar;
