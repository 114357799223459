import GuidelineSection from "./GuidelineSection";

export default function DisclaimerContent({ isPopup = false, className = "" }) {
  return (
    <GuidelineSection isPopup={isPopup} heading={isPopup ? null : "Disclaimer"} className={`${isPopup ? "" : `mt-4`} ${className}`}>
      <span className={isPopup ? "" : "leading-7"}>
        The information presented in the NPS Tagging web application and PowerBI Dashboard is as of 11/01/2024. Certain data sources may not
        be included, such as:
      </span>
      <ul className={`ml-7 ${isPopup ? "" : "leading-7"}`}>
        <li>Merger & Acquisition after 11/01/2024.</li>
        <li>SSOT </li>
        <li>SAP data with unique structure (This SAP data has a distinct format different from standard datasets.)</li>
        <li>any other non-SAP data.</li>
      </ul>
      <span className={isPopup ? "" : "leading-7"}>
        The absence of any data sources may limit the completeness of the insights provided, thus affecting the tagging capability. Efforts
        were made to ensure accuracy and reliability within the boundaries of the included data.
      </span>
    </GuidelineSection>
  );
}
