import React from "react";

function GuidelineSection({ heading, url, className, children, isPopup = false }) {
  return (
    <div className={`${isPopup ? "" : `mb-12`} ${className}`}>
      {heading && <h3 className="mb-3">{heading} </h3>}
      <p>{children} </p>
      {url ? (
        <a href={url} alt="url">
          {url}
        </a>
      ) : null}
    </div>
  );
}

export default GuidelineSection;
