import { useContext } from "react";
import { AppContext } from "../App";
import { useDispatch } from "react-redux";
import { setUpdatesOne, setUpdatesTwo } from "../redux/actions/appActions";

export default function useDiscardChanges() {
  const {
    modal,
    rowsToBeUpdated,
    setRowsToBeUpdated,
    rowsToBeUpdatedForTableTwo,
    setRowsToBeUpdatedForTableTwo,
    salesforceRowsTobeUpdated,
    setSalesforceRowsTobeUpdated,
  } = useContext(AppContext);
  const dispatch = useDispatch();

  const showModal = () => {
    return modal.confirm({
      title: "Do you want to discard changes?",
      content: "There is unsaved work in this page. Make sure you save before switching filters.",
      okText: "Discard changes",
      cancelText: "Close",
      onOk: () => {
        setRowsToBeUpdated([]);
        dispatch(setUpdatesOne({}));
        setRowsToBeUpdatedForTableTwo([]);
        dispatch(setUpdatesTwo({}));
        setSalesforceRowsTobeUpdated([]);
        dispatch(setUpdatesTwo({}));
      },
    });
  };

  const discardChanges = async () => {
    if (rowsToBeUpdated.length > 0 || rowsToBeUpdatedForTableTwo.length > 0 || salesforceRowsTobeUpdated.length > 0) {
      const flag = await showModal();
      return !flag;
    }
    return false;
  };

  return { discardChanges };
}
